import { Controller } from "stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    static targets = ['sticky']
    static values = { offset: Number }

    connect() {
        this.offset = this.hasOffsetValue ? this.offsetValue : 72
        this.isSticky = false;
        
        useIntersection(this, {
            element: this.element,
            threshold: 1,
            rootMargin: (this.offset * -1) + 'px',
        })
    }
    
    appear(entry) {
        if (this.isSticky) {
            this.stickyTarget.style.position = null
            this.stickyTarget.style.top = null
            this.stickyTarget.style.width = null
            this.isSticky = false
        }
    }

    disappear(entry) {
        if (!this.isSticky) {
            this.stickyTarget.style.position = 'fixed'
            this.stickyTarget.style.top = (this.offset - 1) + 'px'
            this.stickyTarget.style.width = this.element.offsetWidth + 'px'
            this.isSticky = true
        }
    }
}