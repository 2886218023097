import { Controller } from "stimulus"
import { bindInput, unbindInput } from '@/libs/binder'

export default class AnswerController extends Controller {
    static targets = ['title']

    connect() {
        this.questionInput = this.element.querySelector('input[id^=id_questions-][id$=-question]')
        bindInput(this, this.questionInput, 'question')
    }

    disconnect() {
        unbindInput(this, this.questionInput, 'question') 
    }

    questionValueChanged(value, oldValue) {
        this.titleTarget.innerHTML = value.length ? value : '&nbsp;'
    }

    onEditToggle(e) {}

    get answerType() {
        return this.data.get('answer-type')
    }
}