import { Controller } from "stimulus"

export default class extends Controller {
    
    connect() {
        if (this.hasErrors) {
            $(this.element).modal();
        }
    }

    get hasErrors() {
        return JSON.parse(this.data.get('hasErrors') || false) 
    }

    get statusIcon() {
        return this.statusIconTarget
    }
}