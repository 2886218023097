import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['html']

    connect() {
        let content,
            isHtml = false

        if (this.hasHtmlTarget) {
            content = this.htmlTarget.innerHTML
            isHtml = true
        }
        else {
            content = this.content
            isHtml = this.isHtml
        }
        $(this.element).popover({
            container: 'body',
            content: content,
            html: isHtml,
            trigger: 'focus hover',
            placement: 'auto'
        })
    }

    get content() {
        return this.data.get('content')
    }

    get isHtml() {
        return JSON.parse(this.data.get('html')) || false
    }
}