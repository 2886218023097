import scssVars from "scss/jsvars.scss"
import { Controller } from 'stimulus'
import Chart from 'chart.js'
import forOwn from 'lodash/forOwn'
import has from 'lodash/has'
import round from 'lodash/round'
import Color from 'chartjs-color'

export default class extends Controller {
    static targets = ['ctx']

    initialize() {
        this.initializeChart()
    }
    
    initializeChart() {
        let chartData = this.getChartData(),
            datasetColor = this.getDatasetColor(chartData)

        if (chartData.isHorizontal) {
            this.ctx.parentNode.style.height = this.getHorizontalChartHeight(chartData)
        }

        this.chart = new Chart(this.ctx, {
            type: chartData.type,
            data: {
                labels: chartData.labels,
                datasets: [{
                    label: chartData.dataset.label,
                    backgroundColor: datasetColor,
                    borderWidth: 0,
                    data: chartData.dataset.data
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title : this.getChartTitle(chartData),
                layout: this.getChartLayout(chartData),
                legend: {
                    display: false
                },
                tooltips: {
                    bodyFontStyle: 'bold',
                    footerFontStyle: 'normal',
                    position: 'nearest',
                    callbacks: {
                        title: (tooltipItems, data) => {
                            return ''
                        },
                        label: (tooltipItem, data) => {
                            let label = data.labels[tooltipItem.index]
                            if (typeof label == 'string' && label.length > 70) {
                                label = label.substr(0, 70) + '…'
                            }
                            return label
                        },
                        footer:  (tooltipItems, data) => {
                            let label = chartData.isHorizontal ? 'xLabel' : 'yLabel'
                            let labels = [data.datasets[0].label + ': ' + tooltipItems[0][label]],
                                index = tooltipItems[0].index
                            
                            forOwn(chartData.extraData, (item, key) => {
                                labels.push(item.label + ': ' + item.data[index])
                            })
                            return labels
                        }
                    }
                },
                scales: this.getScalesConfig(chartData),
                elements: { 
                    point: { 
                        radius: 0,
                        hitRadius: 5, 
                        hoverRadius: 3
                    } 
                },
                plugins: {
                    datalabels: {
                        display: context => {
                            context.isDisplayed = this.isDataLabelDisplayed(context, chartData)
                            return context.isDisplayed
                        },
                        formatter: (value, context) => {
                            context.isOutside = this.isDataLabelOutside(context, chartData)
                            if (chartData.isHorizontal && context.isOutside) {
                                value = '— ' + value
                            }
                            return value
                        },
                        align: context => {
                            return context.isOutside ? 'end' : 'start'
                        },
                        anchor: 'end',
                        color: context => {
                            let meta = context.chart.getDatasetMeta(context.datasetIndex),
                                model = meta.data[context.dataIndex]._model,
                                color = Color(model.backgroundColor)

                            if (!context.isOutside && color.dark()) {
                                return '#ffffff'   
                            }
                            else if (context.isOutside && chartData.dataLabelColorize) {
                                return color.clone().alpha(1).rgbaString()
                            }
                        },
                        font: {
                            weight: 'bold'
                        },
                        offset: context => {
                            return chartData.isHorizontal && context.isOutside ? -2 : 3
                        }
                    }
                }
            }
        })
    }

    getChartData() {
        let parsed = JSON.parse(this.data.get('chartData')),
            labels = [], dataset, extraData = {},
            charted = this.charted || parsed.charted,
            chartType = this.chartType
        
        forOwn(parsed.datasets, (value, key) => {
            if (key == charted) {
                dataset = {
                    label: value,
                    data: [],
                    maxValue: 0
                }
            } else {
                extraData[key] = {
                    label: value,
                    data: []
                }
            }
        })

        parsed.data.forEach(item => {
            forOwn(item, (value, key) => {
                if (key == 'label') {
                    labels.push(value)
                }
                else if (key == charted) {
                    dataset.data.push(round(value, 2))
                    if (value > dataset.maxValue) dataset.maxValue = value
                } else {
                    if (has(extraData, key)) {
                        extraData[key].data.push(round(value, 2))
                    }
                }
            })
        })

        return {
            type: chartType,
            title: parsed.title,
            labels: labels,
            dataset: dataset,
            extraData: extraData,
            isHorizontal: chartType == 'horizontalBar' ? true : false,
            customColors: this.customColors,
            colorizeByValue: this.colorizeByValue,
            dataLabelColorize: this.dataLabelColorize,
            // dataLabelDisplayThreshold: dataset.maxValue.toFixed(2).length * 7
            dataLabelDisplayThreshold: this.ctx.getContext("2d").measureText(dataset.maxValue.toFixed(2).replace('.00', '')).width + 10
        }
    }

    getHorizontalChartHeight(chartData) {
        let height = 16,
            numRows = chartData.labels.length

        if (numRows <= 20) {
            height = 28;
        }
        if (numRows <= 10) {
            height = 40;
        }

        return (100 + numRows * height) + 'px'
    }

    getChartTitle(chartData) {
        if (chartData.title) {
            return {
                display: true,
                text: chartData.title,
                fontSize: 14,
            }
        }
        return null
    }

    getChartLayout(chartData) {
        if (chartData.isHorizontal) {
            return {
                padding: {
                    right: 40
                }
            }
        }
    }

    getScalesConfig(chartData) {
        if (chartData.isHorizontal) {
            return {
                xAxes: this.getVerticalAxesConfig(chartData),
                yAxes: this.getHorizontalAxesConfig(chartData)
            }
        } else {
            return {
                xAxes: this.getHorizontalAxesConfig(chartData),
                yAxes: this.getVerticalAxesConfig(chartData)
            }
        }
    }

    getHorizontalAxesConfig(chartData) {
        return [{
            type: 'category',
            gridLines: {
                display: false
            },
            ticks: {
                callback: value => {
                    if (this.maxLabelLength && value.length > this.maxLabelLength) {
                        return value.substr(0, this.maxLabelLength) + '…'
                    }
                    return value
                }
            },
            maxBarThickness: 40,
            categoryPercentage: chartData.isHorizontal ? 0.6 : 0.8
        }]
    }

    getVerticalAxesConfig(chartData) {
        return [
            {
                type: 'linear',
                position: 'left',
                gridLines: {
                    display: true,
                    drawBorder: false,
                },
                ticks: {
                    beginAtZero: this.beginAtZero,
                    suggestedMin: this.suggestedMin,
                    suggestedMax: this.suggestedMax,
                    stepSize: this.stepSize,
                    padding: 10
                },
                scaleLabel: {
                    display: true,
                    labelString: this.yScaleLabel || chartData.dataset.label,
                    padding: 20,
                }
            }
        ]
    }

    getDatasetColor(chartData) {
        if (chartData.customColors) {
            let colors = []
            
            if (chartData.colorizeByValue) {    
                if (chartData.colorizeByValue == 'x') {
                    let offset = chartData.labels[0] == 0 ? 0 : 1
                    chartData.labels.forEach(item => {
                        colors.push(chartData.customColors[Math.floor(parseFloat(item))-offset])
                    })
                }
                else if (chartData.colorizeByValue == 'y') {
                    chartData.dataset.data.forEach(item => {
                        colors.push(chartData.customColors[Math.floor(parseFloat(item))-1])
                    })
                }

                return colors
            } else {
                let numColors = this.customColors.length

                chartData.dataset.data.forEach((item, i) => {
                    colors.push(chartData.customColors[i % numColors])
                })

                return colors
            }
        } else {
            return Color(scssVars.teal).alpha(0.85).rgbaString()
        }
    }

    isDataLabelDisplayed(context, chartData) {
        if (context.isDisplayed !== undefined) return context.isDisplayed

        if (!chartData.isHorizontal) {
            let meta = context.chart.getDatasetMeta(context.datasetIndex),
                bar = meta.data[context.dataIndex]._model
                
            if (bar.width < chartData.dataLabelDisplayThreshold) {
                return false
            }
        }
        return true
    }

    isDataLabelOutside(context, chartData) {
        if (context.isOutside !== undefined) return context.isOutside

        let meta = context.chart.getDatasetMeta(context.datasetIndex),
            bar = meta.data[context.dataIndex]._model

        if (chartData.isHorizontal) {
            if (bar.height < 12) {
                return true
            }
            if (bar.x - 30 < context.chart.chartArea.left) {
                return true
            }
        } else {
            if (bar.y + 18 > context.chart.chartArea.bottom) {
                return true
            }
        }
        return false
    }

    get ctx() {
        return this.ctxTarget
    }

    get chartType() {
        return this.data.get('type')
    }

    get charted() {
        return this.data.get('charted')
    }

    get customColors() {
        let customColors = this.data.get('customColors')
        if (customColors) {
            return JSON.parse(customColors).map(color => Color(color).alpha(0.85).rgbaString())
        }
        return null
    }

    get colorizeByValue() {
        return this.data.get('colorizeByValue')
    }

    get dataLabelColorize() {
        return JSON.parse(this.data.get('dataLabelColorize'))
    }

    get yScaleLabel() {
        return this.data.get('yScaleLabel')
    }

    get maxLabelLength() {
        let maxLength = this.data.get('maxLabelLength')
        return maxLength !== null ? maxLength : 15
    }

    get beginAtZero() {
        return JSON.parse(this.data.get('beginAtZero'))
    }

    get suggestedMin() {
        return this.data.get('suggestedMin')
    }

    get suggestedMax() {
        return this.data.get('suggestedMax')
    }

    get stepSize() {
        return this.data.get('stepSize')
    }
}