import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['source', 'altSource']
    
    switch(e) {
        const altSource = this.altSourceTarget.value
        this.altSourceTarget.value = this.sourceTarget.value
        this.sourceTarget.value = altSource
    }

}