import scssVars from "scss/jsvars.scss"
import { Controller } from 'stimulus'
import Gauge from '@/libs/gauge'
import Color from 'chartjs-color'


export default class extends Controller {
    static targets = ['gaugeCtx', 'gaugeLabel']

    initialize() {
        let csatData = this.getCsatData()
        
        this.gauge = new Gauge.Gauge(this.gaugeCtx).setOptions({
            angle: 0,
            lineWidth: 0.33,
            pointer: {
              length: 0.45,
              strokeWidth: 0.04,
              color: '#151b1e'
            },
            staticZones: [
               {strokeStyle: this.demotersColor, min: 0, max: 50},
               {strokeStyle: this.passivesColor, min: 50, max: 80},
               {strokeStyle: this.promotersColor, min: 80, max: 100}
            ],
            limitMax: false,
            limitMin: false,
            radiusScale: 1,
            highDpiSupport: true
          })

          this.gauge.animationSpeed = 10
          this.gauge.minValue = 0;
          this.gauge.maxValue = 100;
          this.gauge.setTextField(this.gaugeLabel)
          this.gauge.set(csatData);
    }

    getCsatData() {
        return this.data.get('data')
    }


    get gaugeCtx() {
        return this.gaugeCtxTarget
    }

    get gaugeLabel() {
        return this.gaugeLabelTarget
    }

    get promotersColor() {
        return Color(scssVars.chartGreen).lighten(0.1).rgbaString()
    }

    get passivesColor() {
        return Color(scssVars.chartYellow).lighten(0.1).rgbaString()
    }

    get demotersColor() {
        return Color(scssVars.chartRed).lighten(0.1).rgbaString()
    }
}