import { Controller } from "stimulus"

export default class extends Controller {
    
    connect() {
        this.typeSelect = document.getElementById('id_field_type')

        if (this.typeSelect) {
            this.enumFieldGroup = document.querySelector('.form-group.field-enumeration')
    
            this.toggleEnumRequired(this.typeSelect.value)
    
            this.changeHandler = function(e) {
                this.toggleEnumRequired(e.target.value)
            }.bind(this)
    
            this.typeSelect.addEventListener('change', this.changeHandler, false)
        }
    }

    disconnect() {
        if (this.typeSelect) {
            this.typeSelect.removeEventListener('change', this.changeHandler)
        }
    }

    toggleEnumRequired(field_type) {
        if(field_type != 'enum') {
            this.enumFieldGroup.classList.remove('is-required')
        } else {
            this.enumFieldGroup.classList.add('is-required')
        }
    }
}