import axios from 'axios'
import FormsetController from '../formset_controller'
import { eventDispatcher } from '@/libs/json-event'

export default class extends FormsetController {
    static targets = [ 'body', 'form']

    addQuestion(e) {
        axios({
            method: 'get',
            url: this.addQuestionUrl,
            params: {answer_type: e.target.getAttribute('data-value')},
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(resp => {
            eventDispatcher.create(resp.data).dispatch()
        }).catch(resp => {
            eventDispatcher.create({
                event: 'app:handle-exception',
                selector: '[data-controller="app"]',
                data: {
                    'type': 'network',
                    'resp': resp
                }
            }).dispatch()
        })
    }

    add(e) {
        const addForm = super.add.bind(this)
        $("#ajaxmodal").modal('hide')

        setTimeout(() => {
            addForm(e)
        }, 150)
    }

    edit(e) {
        const form = e.target.closest(this.formSelector)
        const btnDelete = form.querySelector('.btn-form-delete')
        $(form.querySelector('.fields')).collapse('toggle')

        btnDelete.classList.toggle('opacity-1')
        btnDelete.classList.toggle('opacity-0')

        eventDispatcher.create({
            event: 'survey-form:edit',
            selector: '[data-form-index="' + form.getAttribute('data-form-index') + '"]'
        }).dispatch()

    }
    
    delete(e) {
        const form = this.element.querySelector('[data-form-index="' + e.detail.index + '"]')
        $("#ajaxmodal").modal('hide')

        setTimeout(() => {
            if (this.isAnimated) {
                this.deleteFormAnimation(form)
            } else {
                this.deleteForm(form)
            }
        }, 150)
        
    }

    getNewForm(index, e) {
        return this.parseHTML(
            e.detail.template.trim()
                .replace(new RegExp('__prefix__', 'g'), index)
                .replace(new RegExp('__counter__', 'g'), index + 1)
        )
    }

    updateFormState(setOrder, reindex, form, posIndex, dynamicIndex, index) {
        super.updateFormState(setOrder, reindex, form, posIndex, dynamicIndex, index)
        setTimeout(() => {
            form.querySelector('.counter').innerHTML = posIndex + 1
        }, 100)
        
    }

    updateFormIndex(form, index) {
        super.updateFormIndex(form, index)
        const btnDelete = form.querySelector('.btn-form-delete')
        const attr = 'data-ajaxmodal-url'
        btnDelete.setAttribute(attr, btnDelete.getAttribute(attr).replace(new RegExp('/\\d+/'), '/' + index + '/'))
    }

    get addQuestionUrl() {
        return this.data.get('addQuestionUrl')
    }
}