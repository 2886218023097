import { Controller } from "stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    static targets = ['element']
    static values = { threshold: Number }
    static classes = ['firstAppear']

    connect() {
        useIntersection(this, {
            element: this.hasElementTarget ? this.elementTarget : this.element,
            threshold: this.hasThresholdValue ? this.thresholdValue : 0.2
        })
    }
    
    appear(entry) {
        if (this.hasFirstAppearClass && !this.element.classList.contains(this.firstAppearClass)) {
            this.element.classList.add(this.firstAppearClass)
        }
    }
}