import { Controller } from "stimulus"
import { bindInput, unbindInput } from '@/libs/binder'

export default class extends Controller {
    static values = { href: String }
    

    connect() {
        bindInput(this, this.element, 'selected')
    }

    disconnect() {
        unbindInput(this, this.element, 'selected') 
    }

    selectedValueChanged(value, oldValue) {
        let location = this.href + (this.name ? this.getQueryParams(value) : value)
        Turbolinks.visit(location)
    }

    getQueryParams(value) {
        let params = new URLSearchParams(document.location.search)
        if(this.name) {
            params.set(this.name, value)
        }
        return '?' + params.toString()
    }

    get location() {
        let param 
    }

    get name() {
        return this.element.getAttribute('name')
    }

    get href() {
        return this.hrefValue || window.location.href.split('?')[0]
    }
}