import "../../scss/style.scss";

import "core-js/es/array/find"
import "core-js/es/array/find-index"
import "core-js/es/array/from"
import "core-js/es/map"
import "core-js/es/object/assign"
import "core-js/es/promise"
import "core-js/es/set"
import "core-js/features/url-search-params"
import "mutation-observer-inner-html-shim"
import "@/polyfills/ie11"

import axios from 'axios';
import moment from 'moment'
import Chart from 'chart.js'
import Color from 'chartjs-color'
import 'chartjs-plugin-datalabels'

import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/popover'
import 'bootstrap/js/dist/collapse'
import serialize from 'form-serialize'
import Turbolinks from 'turbolinks'

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import { eventDispatcher } from '@/libs/json-event'

// Overrides
Color.prototype.dark = function(thershold) {
    let rgb = this.values.rgb,
		yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
    return yiq < (thershold || 160)
}

Chart.helpers.getHoverColor = function(colorValue) {
    return (colorValue instanceof CanvasPattern) ? colorValue : Color(colorValue).darken(0.3).rgbString()
}

Chart.defaults.global.plugins.datalabels = false
Chart.defaults.global.legendCallback = function(chart) {
    let text = [], dataset, barType;

    text.push('<ul class="' + chart.id + '-legend">')
    for (var i = 0; i < chart.data.datasets.length; i++) {
        dataset = chart.data.datasets[i]
        barType = dataset.type || ''
        text.push('<li><span class="' + barType + '" style="background-color:' + dataset.backgroundColor + '"></span>')
        if (dataset.label) {
            text.push(dataset.label)
        }
        text.push('</li>')
    }
    text.push('</ul>')
    return text.join('')
}

document.addEventListener("turbolinks:load", (event) => {
    if ("djdt" in window) {
        window.djdt.init()
    }
    
    $('a[data-toggle="pill"]').on('show.bs.tab', e => {
        let tabId = e.target.getAttribute('href')
        $(tabId).closest('form').attr('data-active-tab', tabId.replace('#', ''));
    })

    $('[data-toggle="tooltip"]').tooltip()
});

document.addEventListener('submit', e => {
    if (e.target.matches('[method=get]:not([data-remote=false])')) {
        e.preventDefault()
        $('.modal.show').modal('hide')
        let path = e.target.getAttribute('action') || document.location.pathname,
            params = serialize(e.target)
        if (params) path = '?' + params
        Turbolinks.visit(path)
    }
    else if (e.target.matches('[method=post]:not([data-remote=false])')) {
        e.preventDefault()

        const referrer = document.location.href
        const data = new FormData(e.target)
        const submitter = e.target.getAttribute('data-submitter')
        const activeTab = e.target.getAttribute('data-active-tab')
        
        if (submitter) {
            data.append(submitter, true)
            e.target.removeAttribute('data-submitter')
        }

        if (activeTab) {
            data.append('_active_tab', activeTab)
            e.target.removeAttribute('data-active-tab')
        }
        
        axios({
            method: 'post',
            url: e.target.getAttribute('action') || document.location.href,
            data: data,
            headers: {
                'Turbolinks-Referrer': referrer,
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(resp => {
            if (resp.headers['content-type'] == 'application/json') {
                eventDispatcher.create(resp.data).dispatch()
            } else {
                const snapshot = Turbolinks.Snapshot.wrap(resp.data)
                Turbolinks.controller.cache.put(referrer, snapshot)
                Turbolinks.visit(referrer, {action: "restore"})    

                document.addEventListener("turbolinks:load", function handler() {
                    Turbolinks.clearCache()
                    document.removeEventListener("turbolinks:load", handler)
                }, false)
            }
        }).catch(resp => {
            eventDispatcher.create({
                event: 'app:handle-exception',
                selector: '[data-controller="app"]',
                data: {
                    'type': 'network',
                    'resp': resp
                }
            }).dispatch()
        })
    }
}, false)

document.addEventListener('click', e => {
    if (e.target.matches('input[name="_continue"], input[name="_addnew"]')) {
        e.target.form.setAttribute('data-submitter', e.target.getAttribute('name'))
    }
}, false)

document.addEventListener('turbolinks:before-cache', () => {
    $('.modal').removeClass('fade').modal('hide').addClass('fade')
    $('[data-controller=popover]').popover('hide')
    $('body').trigger('click')
}, false);

document.addEventListener('app:post-submit-redirect', e => {
    const location = e.detail ? e.detail.location : document.location.href
    const clearCache = e.detail ? e.detail.clearCache : true

    Turbolinks.visit(location)
    
    if (clearCache) {
        document.addEventListener("turbolinks:load", function handler() {
            Turbolinks.clearCache()
            document.removeEventListener("turbolinks:load", handler)
        }, false)
    }
}, false)

document.addEventListener('DOMContentLoaded', e => {
    if (JSON.parse(localStorage.getItem('app-sidebar-hidden'))) {
        document.body.classList.add('sidebar-hidden')
    } else {
        document.body.classList.remove('sidebar-hidden')
    }
    document.body.classList.remove('sidebar-mobile-show')
}, false)

document.addEventListener('turbolinks:before-render', e => {
    if (JSON.parse(localStorage.getItem('app-sidebar-hidden'))) {
        e.data.newBody.classList.add('sidebar-hidden')
    } else {
        e.data.newBody.classList.remove('sidebar-hidden')
    }
    e.data.newBody.classList.remove('sidebar-mobile-show')
}, false);

moment.locale(document.documentElement.lang)

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

Turbolinks.start()