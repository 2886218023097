import { Controller } from "stimulus"
import axios from 'axios'
import { eventDispatcher } from '@/libs/json-event'

export default class extends Controller {
    static targets = ['dialog', 'content']

    connect() {
        this.clickHandler = function(e) {
            let el = e.target,
                found = false
            
            while (el && !(found = el.matches('[data-action="ajaxmodal#open"]'))) {
                el = el.parentElement;
            }

            if (found) {
                e.preventDefault()
                let url = el.getAttribute(el.hasAttribute('href') ? 'href' : 'data-ajaxmodal-url')
                let dialog_size = el.getAttribute('data-modal-size')

                if (dialog_size) {
                    this.modalDialog.classList.add('modal-' + dialog_size)
                }

                this.open(url)
                
                $(this.element).one('hidden.bs.modal', function handler(e) {
                    this.modalDialog.classList.remove('modal-lg');
                }.bind(this))
            }
        }.bind(this)

        document.addEventListener('click', this.clickHandler, false)
    }

    disconnect() {
        document.removeEventListener('click', this.clickHandler)
    }

    open(url) {
        axios({
            method: 'get',
            url: url,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(resp => {
            if (resp.headers['content-type'] == 'application/json') {
                eventDispatcher.create(resp.data).dispatch()
            } else {
                this.modalContent.innerHTML = resp.data
                $(this.element).modal()
            }
        }).catch(resp => {
            eventDispatcher.create({
                event: 'app:handle-exception',
                selector: '[data-controller="app"]',
                data: {
                    'type': 'network',
                    'resp': resp
                }
            }).dispatch()
        })
    }

    load(e) {
        this.modalContent.innerHTML = e.detail.template
        $(this.element).modal()
    }

    close(e) {
        const location = e.detail ? e.detail.location : document.location.href
        $(this.element).one('hidden.bs.modal', function handler(e) {
            Turbolinks.visit(location)
            
            document.addEventListener("turbolinks:load", function handler() {
                Turbolinks.clearCache()
                document.removeEventListener("turbolinks:load", handler)
            }, false)
        })
        $(this.element).modal('hide')
    }

    get modalDialog() {
        return this.dialogTarget
    }

    get modalContent() {
        return this.contentTarget
    }
}