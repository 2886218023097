import { Controller } from "stimulus"
import axios from 'axios'
import { eventDispatcher } from '@/libs/json-event'

export default class extends Controller {
    static values = { src: String }

    connect() {
        axios({
            method: 'get',
            url: this.srcValue,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(resp => {
            if (resp.headers['content-type'] == 'application/json') {
                eventDispatcher.create(resp.data).dispatch()
            } else {
                this.element.innerHTML = resp.data
            }
        }).catch(resp => {
            eventDispatcher.create({
                event: 'app:handle-exception',
                selector: '[data-controller="app"]',
                data: {
                    'type': 'network',
                    'resp': resp
                }
            }).dispatch()
        })
    }
}