import { Controller } from "stimulus"
import Cookies from 'js-cookie'
import CustomEvent from 'custom-event'


export default class extends Controller {
    static targets = ['sidebar', 'errorModal', 'errorModalBody']

    initialize() {
        if (this.iOS) {
            this.element.classList.add('iOS')
        }
    }

    transitionEnd(e) {
        if (this.sidebar && e.target == this.sidebar) {
            this.resizeBroadcast()
        }
    }
    
    toggleSidebar(e) {
        this.body.classList.toggle('sidebar-hidden')
        const sidebarHidden = this.body.classList.contains('sidebar-hidden')
        localStorage.setItem('app-sidebar-hidden', sidebarHidden)
        Cookies.set('_ifbsbh', sidebarHidden, { expires: 365 * 10 })
        this.resizeBroadcast()
    }

    toggleSidebarMobile() {
        this.body.classList.toggle('sidebar-mobile-show')
        this.resizeBroadcast()
    }

    resizeBroadcast() {
        let timesRun = 0
        document.dispatchEvent(new CustomEvent('app:sidebar-resizestart'))
        const interval = setInterval(() => {
          timesRun += 1;
          if (timesRun === 5) {
              clearInterval(interval)
              document.dispatchEvent(new CustomEvent('app:sidebar-resizeend'))
          }
          dispatchEvent(new CustomEvent('resize'))
        }, 62.5);
    }

    handleException(e) {
        const openModal = $('.modal.show')
        
        this.errorModalBodyTarget.innerHTML = '<p>' + gettext('Prišlo je do nepričakovane napake. Prosimo poizkusite ponovo.') + '</p>'
        $(this.errorModalTarget).modal()
        
        if (openModal) {
            openModal.modal('hide')

            $(this.errorModalTarget).one('hide.bs.modal', e => {
                openModal.modal('show')
            })
        }
    }

    get body() {
        return this.element
    }

    get sidebar() {
        try {
            return this.sidebarTarget
        } catch(e) {}
        
        return false
    }

    get iOS() {
        return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }
}