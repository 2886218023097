import { Controller } from "stimulus"

export default class extends Controller {
    
    connect() {        
        this.typeSelect = this.element.querySelectorAll('input[name=appeal_type]')
        this.phoneNumbers = this.element.querySelector('.form-group.field-phone_numbers')
        this.textSms = this.element.querySelector('.form-group.field-text_sms')
        this.emails = this.element.querySelector('.form-group.field-emails')
        this.textEmail = this.element.querySelector('.form-group.field-text_email')

        this.toggleFields(this.element.querySelector('input[name=appeal_type]:checked').value)

        this.changeHandler = function(e) {
            this.toggleFields(e.target.value)
        }.bind(this)

        Array.from(this.typeSelect).forEach(item => {
            item.addEventListener('change', this.changeHandler, false)
        })
    }

    disconnect() {
        Array.from(this.typeSelect).forEach(item => {
            item.removeEventListener('change', this.changeHandler, false)
        })
    }

    toggleFields(appeal_type, animate) {
        if (appeal_type == '1') {
            this.hideField('emails')
            this.hideField('textEmail')
            this.showField('phoneNumbers')
            this.showField('textSms')
        } else {
            this.hideField('phoneNumbers')
            this.hideField('textSms')
            this.showField('emails')
            this.showField('textEmail')
        }
    }

    hideField(fieldName) {
        let el = this[fieldName]
        Array.from(el.querySelectorAll('textarea')).forEach(item => {
            item.setAttribute('disabled', 'disabled')
        })
        el.classList.add('d-none')
    }

    showField(fieldName) {
        let el = this[fieldName]

        Array.from(el.querySelectorAll('textarea')).forEach(item => {
            item.removeAttribute('disabled')
        })
        el.classList.remove('d-none')
    }
}