import { Controller } from 'stimulus'
import flatpicker from 'flatpickr'
import { english } from 'flatpickr/dist/l10n/default.js'
import { Slovenian } from 'flatpickr/dist/l10n/sl.js'
import { Croatian } from 'flatpickr/dist/l10n/hr.js'

export default class extends Controller {
    initialize() {
        const pickerContainer = document.getElementById('flatpickr-container')

        this.picker = flatpickr(this.element, {
            allowInput: true,
            noCalendar: !this.showDatePicker,
            enableTime: this.showTimePicker,
            dateFormat: this.dateFormat,
            locale: this.locale,
            time_24hr: true,
            disableMobile: true,
            autoFillDefaultTime: false,
            monthSelectorType: 'static',
            appendTo: pickerContainer,
            nextArrow: '<i class="fas fa-caret-right font-lg"></i>',
            prevArrow: '<i class="fas fa-caret-left font-lg" ></i>',

            onOpen: function(selectedDates, dateStr, instance) {
                if (!instance.fpContainer) {
                    let position = window.getComputedStyle(pickerContainer).getPropertyValue('position')
                    instance.fpContainer = position == 'fixed' ? pickerContainer : null
                }
                if (instance.fpContainer) {
                    instance.input.setAttribute('disabled', 'disabled')
                    instance.fpContainer.style.display = 'flex'
                    
                    let clickCloseHandler = function(e) {
                        if(e.target == pickerContainer) {
                            e.stopPropagation();
                            pickerContainer.removeEventListener(e.type, clickCloseHandler)
                            instance.close()
                        }
                    }

                    pickerContainer.addEventListener('click', clickCloseHandler, false)
                }
            },
            onClose: function(selectedDates, dateStr, instance) {
                if (instance.fpContainer) {
                    instance.fpContainer.style.display = 'none'
                    instance.input.removeAttribute('disabled')
                }
            }
        })
    }

    get showDatePicker() {
        return JSON.parse(this.data.get('showDatePicker'))
    }

    get showTimePicker() {
        return JSON.parse(this.data.get('showTimePicker'))
    }

    get dateFormat() {
        return this.data.get('dateFormat')
    }

    get locale() {
        let langMapper = {
            'en': english,
            'sl': Slovenian,
            'hr': Croatian
        } 
        return langMapper[document.documentElement.lang]
    }
}