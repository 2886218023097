import scssVars from "scss/jsvars.scss"
import { Controller } from 'stimulus'
import Chart from 'chart.js'
import moment from 'moment'
import round from 'lodash/round'
import Color from 'chartjs-color'


export default class extends Controller {
    static targets = ['ctx', 'legend']

    initialize() {
        this.initializeChart()
    }

    initializeChart() {
        let chartData = this.getChartData(),
            lineColor = Color(scssVars.chartRed).rgbaString(),
            barColor = Color(scssVars.teal).alpha(0.85).rgbaString(),
            datasets = []

        chartData.datasets.forEach(item => {
            if (item.key == 'count') {
                datasets.push({
                    label: item.label,
                    type: 'bar',
                    backgroundColor: barColor,
                    borderWidth: 0,
                    yAxisID: 'count-y-axis',
                    data: chartData.data[item.key]
                })    
            }
            else {
                datasets.push({
                    label: item.label,
                    type: 'line',
                    fill: false,
                    backgroundColor: item.color || lineColor,
                    borderColor: item.color || lineColor,
                    borderWidth: 3,
                    lineTension: 0.1,
                    spanGaps: true,
                    yAxisID: 'avg-y-axis',
                    data: chartData.data[item.key]
                })
            }
            
        })
        
        this.chart = new Chart(this.ctx, {
            type: 'bar',
            data: {
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,  
                },
                tooltips: {
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        title: function(tooltips, data) {
                            let title = ''
                            if (tooltips.length > 0) {                                
                                let item = tooltips[tooltips.length - 1];

                                if (chartData.period == 'hour') {
                                    let hour = this.parseDate(chartData.period, data.datasets[item.datasetIndex].data[item.index].t)
                                    title = hour.format('H[h]') + ' - ' + moment(hour).add(1 ,'h').format('H[h]')
                                } else if (chartData.period == 'week') {
                                    let week = this.parseDate(chartData.period, data.datasets[item.datasetIndex].data[item.index].t)
                                    title = week.startOf('week').format('D. MMM YYYY') + ' - ' + week.endOf('week').format('D. MMM YYYY')
                                } else {
                                    title = item.xLabel;
                                }
                            }            
                            return title
                        }.bind(this),
                        label: function(tooltip, data) {
                            let label = typeof tooltip.yLabel == 'number' ?  round(tooltip.yLabel, 2) : tooltip.yLabel
                            return `${data.datasets[tooltip.datasetIndex].label}: ${label}`
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        offset: true,
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            source: 'auto',
                            autoSkip: true,
                            maxRotation: 90,
                            callback: function(value, index, values) {
                                if (chartData.period == 'week') {
                                    let week = moment(values[index].value)
                                    return week.startOf('week').format('D.M') + ' - ' + week.endOf('week').format('D.M')
                                } 
                                return value
                            }
                        },
                        time: {
                            tooltipFormat: this.getTooltipFormat(chartData.period),
                            unit: chartData.period,
                            displayFormats: {
                                hour: 'H[h]',
                                day: 'D. MMM',
                            },
                            parser: function(value) {
                                return this.parseDate(chartData.period, value)
                            }.bind(this)
                        },
                        bounds: 'data',
                        maxBarThickness: 25
                    }],
                    yAxes: [
                        {
                            id: 'count-y-axis',
                            type: 'linear',
                            position: 'left',
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                min: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.countLabel,
                                padding: 20,
                            }
                        },
                        {
                            id: 'avg-y-axis',
                            type: 'linear',
                            position: 'right',
                            gridLines: {
                                display: true,
                                drawBorder: false,
                            },
                            ticks: {
                                stepSize: 1,
                                suggestedMin: 0
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.avgGradeLabel,
                                padding: 20,
                            }
                        }
                    ]
                },
                elements: { 
                    point: { 
                        radius: 0,
                        hitRadius: 5, 
                        hoverRadius: 3
                    } 
                },
                plugins: {
                    datalabels: false
                }
            }
        })

        this.legend.innerHTML = this.chart.generateLegend()
    }

    getChartData() {
        let parsed = JSON.parse(this.data.get('chartData'))
        
        return {
            period: parsed.period,
            datasets: parsed.datasets,
            data: parsed.data
        }
    }

    parseDate(period, date) {
        switch(period) {
            case 'hour':
                return moment(date, 'HH')
            case 'week':
                return moment(date, 'YYYY-W')
            default:
                return moment(date)
        }
    }

    getTooltipFormat(period) {
        switch(period) {
            case 'hour':
                return 'HH:mm'
            case 'day':
                return 'D. MMMM YYYY'
            case 'week':
                return 'w YYYY'
            case 'month':
                return 'MMMM YYYY'
        }
        return null
    }

    get ctx() {
        return this.ctxTarget
    }

    get legend() {
        return this.legendTarget
    }

    get avgGradeLabel() {
        return this.data.get('avgGradeLabel')
    }

    get countLabel() {
        return this.data.get('countLabel')
    }
}