import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ 'source', 'statusIcon' ]
    
    connect() {
        if (document.queryCommandSupported("copy")) {
            this.element.classList.add("clipboard-supported")
        }
    }

    copy() {
        if (document.queryCommandSupported("copy")) {
            this.source.select()
            document.execCommand("copy")

            this.source.selectionEnd = this.source.selectionStart
            this.source.blur()

            this.statusIcon.classList.remove('fa-copy')
            this.statusIcon.classList.add('fa-check')

            setTimeout(() => {
                this.statusIcon.classList.remove('fa-check')
                this.statusIcon.classList.add('fa-copy')
              }, 1500)
        }
    }

    get source() {
        return this.sourceTarget
    }

    get statusIcon() {
        return this.statusIconTarget
    }
}