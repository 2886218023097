import autosize from 'autosize'
import { bindInput, unbindInput, bindEvent, unbindEvent } from '@/libs/binder'
import AnswerController from './answer_controller'

export default class extends AnswerController {
    connect() {
        super.connect()

        this.limitExactField = this.element.querySelector('[data-limit-option="exact"]')
        this.limitRangeField = this.element.querySelector('[data-limit-option="range"]')

        this.choicesTextarea = this.element.querySelector('textarea[name$=-config_choices]')
        this.limitSelect = this.element.querySelector('select[name$=-config_limit]')
        this.limitExactInput = this.element.querySelector('input[name$=-config_limit_exact]')
        this.limitMinInput = this.element.querySelector('input[name$=-config_limit_min]')
        this.limitMaxInput = this.element.querySelector('input[name$=-config_limit_max]')

        autosize(this.choicesTextarea)
        bindInput(this, this.limitSelect , 'limit')
        bindInput(this, this.limitExactInput, 'limitExact')
        bindInput(this, this.limitMinInput, 'limitMin')
        bindInput(this, this.limitMaxInput, 'limitMax')
        
        this.limitValue = this.limitSelect.value
        
        this.updateLimitInputsMax(this.numChoices)
        bindEvent(this, this.choicesTextarea, 'keydown', 'choicesTextareaKeyDown')
    }

    disconnect() {
        unbindEvent(this, this.choicesTextarea, 'keydown', 'choicesTextareaKeyDown')
        unbindInput(this, this.limitMaxInput, 'limitMax')
        unbindInput(this, this.limitMinInput, 'limitMin')
        unbindInput(this, this.limitExactInput, 'limitExact')
        unbindInput(this, this.limitSelect, 'limit')
    }

    onEditToggle(e) {
        autosize.update(this.choicesTextarea)
    }

    choicesTextareaKeyDown(e) {
        if (e.keyCode == 13 || e.key == 'Enter') {
            const lineInfo = this.choicesTextareaLineInfo()

            if (lineInfo.lineText == '') {
                e.preventDefault()
                return false;
            }
        }
        this.updateLimitInputsMax(this.numChoices)
    }

    limitValueChanged(value, oldValue) {
        this.toogleLimitFields()
        
        if (value == 'unlimited') {
            this.limitExactValue = 1
        }
        else if (value == 'exact' && oldValue == 'range') {
            this.limitExactValue = this.limitMinValue
        }
        else if (value == 'range' && oldValue == 'exact') {
            this.limitMinValue = this.limitExactValue
            this.limitMaxValue = this.limitExactValue
        }
    }

    limitExactValueChanged(value, oldValue) {
        this.limitMinValue = value
        this.limitMaxValue = value
    }

    limitMinValueChanged(value, oldValue) {
        if (this.limitMaxValue && value > this.limitMaxValue) {
            this.limitMaxValue = value
        }
        this.limitMaxInput.setAttribute('min', value)
    }

    choicesTextareaLineInfo() {
        if (this.choicesTextarea) {
            const lineNum = this.choicesTextarea.value.substr(0, this.choicesTextarea.selectionStart).split(/\r?\n|\r/).length
            const lineText = this.choicesTextarea.value.split(/\r?\n|\r/)[lineNum - 1]
            return {
                lineNum: lineNum,
                lineText: lineText
            }
        }
    }

    toogleLimitFields() {
        if (this.limitValue == 'unlimited') {
            this.limitExactField.classList.add('d-none')
            this.limitRangeField.classList.add('d-none')
        }
        else {
            this.limitValue == 'exact' ? this.limitExactField.classList.remove('d-none') : this.limitExactField.classList.add('d-none')
            this.limitValue == 'range' ? this.limitRangeField.classList.remove('d-none') : this.limitRangeField.classList.add('d-none')
        }
    }

    updateLimitInputsMax(numChoices) {
        this.limitExactInput.setAttribute('max', numChoices)
        this.limitMinInput.setAttribute('max', numChoices)
        this.limitMaxInput.setAttribute('max', numChoices)

        if (this.limitExactValue > numChoices) {
            this.limitExactValue = numChoices
        }

        if (this.limitMinValue > numChoices) {
            this.limitMinValue = numChoices
        }

        if (this.limitMaxValue > numChoices) {
            this.limitMaxValue = numChoices
        }
    }

    get numChoices() {
        return this.choicesTextarea.value.split(/\r?\n|\r/).length
    }
}