import scssVars from "scss/jsvars.scss"
import { Controller } from 'stimulus'
import Chart from 'chart.js'
import Gauge from '@/libs/gauge'
import Color from 'chartjs-color'


export default class extends Controller {
    static targets = ['chartCtx', 'chartLegend', 'gaugeCtx', 'gaugeLabel']

    initialize() {
        let npsData = this.getNpsData()
        
        this.initializeChart(npsData)
        this.initializeGauge(npsData)
    }

    initializeChart(npsData) {
        this.chart = new Chart(this.chartCtx, {
            type: 'horizontalBar',
            data: {
                datasets: [
                    {
                        label: this.demotersLabel,
                        backgroundColor: this.demotersColor,
                        borderWidth: 0,
                        data: [npsData.demoters],
                        stack: 'nps',
                    },
                    {
                        label: this.passivesLabel,
                        backgroundColor: this.passivesColor,
                        borderWidth: 0,
                        data: [npsData.passives],
                        stack: 'nps'
                    },
                    {
                        label: this.promotersLabel,
                        backgroundColor: this.promotersColor,
                        borderWidth: 0,
                        data: [npsData.promoters],
                        stack: 'nps'
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 0,
                        left: 30,
                        right: 40,
                        bottom: 0
                    }
                },
                legend: {
                    display: false
                },
                tooltips: {
                    mode: 'x',
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let label = data.datasets[tooltipItem.datasetIndex].label
                            return  label + ': ' + tooltipItem.xLabel + ' %'
                        }
                    }
                },
                hover: {
                    mode: 'single'
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        type: 'linear',
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            drawTicks: false,
                        },
                        ticks: {
                            display: true,
                            min: 0,
                            max: 100,
                            stepSize: 100,
                            padding: 10
                        }
                    }]
                },
                plugins: {
                    datalabels: {
                        font: {
                            weight: 'bold',
                            size: 11
                        },
                        padding: 2,
                        borderRadius: 4,
                        offset: 2,
                        display: context => {
                            context.dataValue = context.dataset.data[context.dataIndex]
                            context.isDisplayed = context.dataValue > 0
                            return context.isDisplayed

                        },
                        formatter: (value, context) => {
                            return value + ' %'
                        },
                        anchor: context => {
                            switch(context.datasetIndex) {
                                case 0: return 'start'; break
                                case 1: return 'center'; break
                                case 2: return 'end'; break
                            }
                        },
                        align: context => {
                            if (context.isDisplayed && context.datasetIndex == 0) {
                                return context.dataValue <= 12 ? 'start' : 'end'
                            }
                            else if (context.isDisplayed && context.datasetIndex == 2) {
                                return context.dataValue <= 12 ? 'end' : 'start'
                            }
                            return 'center'
                        },
                        backgroundColor: context => {
                            let meta = context.chart.getDatasetMeta(context.datasetIndex),
                                model = meta.data[context.dataIndex]._model,
                                color = Color(model.backgroundColor)
                            
                            context.backgroundColor = color
                            return color.rgbaString()
                        },
                        color: context => {
                            if (context.backgroundColor.dark()) {
                                return '#ffffff'   
                            }
                        }
                    }
                }
            }
        })

        this.chartLegend.innerHTML = this.chart.generateLegend()
    }

    initializeGauge(npsData) {
        this.gauge = new Gauge.Gauge(this.gaugeCtx).setOptions({
            angle: -0.25,
            lineWidth: 0.22,
            pointer: {
              length: 0.5,
              strokeWidth: 0.035,
              color: '#151b1e'
            },
            staticZones: [
               {strokeStyle: this.demotersColor, min: -100, max: -30},
               {strokeStyle: this.passivesColor, min: -30, max: 30},
               {strokeStyle: this.promotersColor, min: 30, max: 100}
            ],
            limitMax: false,
            limitMin: false,
            radiusScale: 1,
            highDpiSupport: true
          })

          this.gauge.animationSpeed = 10
          this.gauge.minValue = -100;
          this.gauge.maxValue = 100;
          this.gauge.setTextField(this.gaugeLabel)
          this.gauge.set(npsData.index);
          
    }

    getNpsData() {
        return JSON.parse(this.data.get('data'))
    }

    get chartCtx() {
        return this.chartCtxTarget
    }

    get chartLegend() {
        return this.chartLegendTarget
    }

    get gaugeCtx() {
        return this.gaugeCtxTarget
    }

    get gaugeLabel() {
        return this.gaugeLabelTarget
    }

    get promotersLabel() {
        return this.data.get('promotersLabel')
    }

    get passivesLabel() {
        return this.data.get('passivesLabel')
    }

    get demotersLabel() {
        return this.data.get('demotersLabel')
    }

    get promotersColor() {
        return Color(scssVars.chartGreen).lighten(0.1).rgbaString()
    }

    get passivesColor() {
        return Color(scssVars.chartYellow).lighten(0.1).rgbaString()
    }

    get demotersColor() {
        return Color(scssVars.chartRed).lighten(0.1).rgbaString()
    }
}