import { String } from 'core-js'
import { Controller } from 'stimulus'


export default class FormsetController extends Controller {
    static targets = [ 'body', 'emptyForm', 'form']
    static values = { managementFormParent: String }
    
    initialize() {
        this.updateFormsetState(true, false)
    }

    add(e) {
        if (!this.canAdd) {
            return false
        }

        let index = this.formCount,
            form = this.getNewForm(index, e),
            ordInput = this.getFormInput('ORDER', form, index)
        
        if (ordInput) {
            ordInput.setAttribute('value', index + 1)
        }
        
        form.setAttribute(`data-${ this.identifier }-target`, 'form')
        
        if (this.isAnimated) {
            this.addFormAnimation(form)
        }
        else {
            this.body.appendChild(form)
        }

        this.updateFormsetState(false, false)
    }

    getNewForm(index, e) {
        return this.parseHTML(this.emptyForm.innerHTML.trim().replace(new RegExp('__prefix__', 'g'), index))
    }
    
    delete(e) {
        if (!this.canDelete) {
            return false
        }

        let form = e.target.closest(this.formSelector)
        
        if (this.isAnimated) {
            this.deleteFormAnimation(form)
        } else {
            this.deleteForm(form)
        }
    }

    deleteForm(form) {
        let posIndex = this.forms.indexOf(form),
            index = this.getFormIndex(form),
            delInput = this.getFormInput('DELETE', form, index)
        
        if (posIndex > -1) {
            if (delInput && index < this.initialFormCount) {
                delInput.setAttribute('checked', 'checked')
                form.setAttribute('data-form-deleted', 'true')
                form.classList.add(this.deletedClass)
            } else {
                this.body.removeChild(form)    
                form = undefined
            }
            this.updateFormsetState(true, true)
            return form
        }
    }

    moveUp(e) {
        this.move(e, 1)
    }

    moveDown(e) {
        this.move(e, -1)
    }

    move(e, direction) {
        let form = e.target.closest(this.formSelector),
            index = this.activeForms.indexOf(form)
        
        if ((direction > 0 && index > 0) || (direction < 0 && index < this.totalFormCount - 1)) {
            let target = this.activeForms[index-direction],
                formOldBox = form.getBoundingClientRect(),
                targetOldBox = target.getBoundingClientRect()
                
            if (direction > 0) {
                this.body.insertBefore(form, target)
            } else {
                this.body.insertBefore(target, form)
            }
            
            if (this.isAnimated) {
                this.moveFormAnimation(form, target, formOldBox, targetOldBox)
            }
            
            this.updateFormsetState(true, false)
        }
    }

    getFormIndex(form){
        return parseInt(form.getAttribute('data-form-index'))
    }

    getFormInput(name, parent, index) {
        let prefix = index !== undefined ? this.formsetPrefix + '-' + index : this.formsetPrefix,
            selector = '[name=' + prefix + '-' + name + ']'
        return parent.querySelector(selector)
    }    

    updateFormsetState(setOrder, reindex) {
        let btnAdd = this.element.querySelector(this.btnAddSelector),
            dynamicIndex = this.initialFormCount
        
        this.forms.forEach((form, posIndex) => {
            let index = this.getFormIndex(form),
                isDeleted = form.hasAttribute('data-form-deleted')
            
            this.updateFormState(setOrder, reindex, form, posIndex, dynamicIndex, index, isDeleted)
            
        }, this)

        if (btnAdd) {
            if (this.canAdd) {
                btnAdd.removeAttribute('disabled')
            } else {
                btnAdd.setAttribute('disabled', 'disabled')
            }
        }

        this.totalFormCount = this.formCount
    }

    updateFormState(setOrder, reindex, form, posIndex, dynamicIndex, index, isDeleted) {
        if (reindex && index >= this.initialFormCount) {
            index = dynamicIndex
            this.updateFormIndex(form, index)
            dynamicIndex++
        }

        if (setOrder) {
            let ordInput = this.getFormInput('ORDER', form, index)
            if (ordInput) ordInput.setAttribute('value',  isDeleted ? '' : this.activeForms.indexOf(form) + 1)
        }
        
        if (!isDeleted) {
            this.updateFormButtonState(form, posIndex)
        }
    }

    updateFormIndex(form, index) {
        let prefix = this.formsetPrefix,
            idRegex = new RegExp(prefix + '-(\\d+|__prefix__)-'),
            replacement = prefix + '-' + index + '-'
        
        form.setAttribute('data-form-index', index)
        Array.from(form.querySelectorAll('input,select,textarea,label')).forEach(function(el, i) {
            if (el.hasAttribute('id')) el.setAttribute('id', el.getAttribute('id').replace(idRegex, replacement))
            if (el.hasAttribute('for')) el.setAttribute('for', el.getAttribute('for').replace(idRegex, replacement))
            if (el.hasAttribute('name')) el.setAttribute('name', el.getAttribute('name').replace(idRegex, replacement))
        }, this)
    }

    updateFormButtonState(form, index) {
        let btnDelete = form.querySelector(this.btnDeleteSelector),
            btnMoveUp = form.querySelector(this.btnMoveUpSelector),
            btnMoveDown = form.querySelector(this.btnMoveDownSelector)
        
        if (btnMoveUp) {
            if (index == 0) {
                btnMoveUp.setAttribute('disabled', 'disabled')
            } else {
                btnMoveUp.removeAttribute('disabled')
            }
        }

        if (btnMoveDown) {
            if (index == this.activeFormCount - 1) {
                btnMoveDown.setAttribute('disabled', 'disabled')
            } else {
                btnMoveDown.removeAttribute('disabled')
            }
        }

        if (btnDelete) {
            if (this.canDelete) {
                btnDelete.removeAttribute('disabled')
            } else {
                btnDelete.setAttribute('disabled', 'disabled')
            }
        }
    }

    addFormAnimation(form) {
        form.style.transition = ''
        form.style.opacity = 0
        form.style.height = 0
        form.style.marginTop = 0
        form.style.marginBottom = 0
        form.style.paddingTop = 0
        form.style.paddingBottom = 0
        
        this.body.appendChild(form)
        
        requestAnimationFrame(function() {
            form.style.transition = '200ms, opacity 150ms ease-in 50ms'
            form.style.height = form.scrollHeight + 'px'
            form.style.opacity = null
            form.style.marginTop = null
            form.style.marginBottom = null
            form.style.paddingTop = null
            form.style.paddingBottom = null
        })

        let animationEndHandler = function(e) {
            if (e.target.matches(this.formSelector) && e.propertyName == 'opacity') {
                form.removeEventListener(e.type, animationEndHandler)
                form.style.height = null
                this.clearAnimations(form) 
            }
        }.bind(this) 

        form.addEventListener('transitionend', animationEndHandler, false)
    }

    deleteFormAnimation(form) {            
        requestAnimationFrame(function() {
            form.style.transition = ''
            form.style.height = form.scrollHeight + 'px'

            requestAnimationFrame(function() {
                form.style.transition = '200ms ease 100ms, opacity 150ms ease-in'
                form.style.opacity = 0
                form.style.height = 0
                form.style.marginTop = 0
                form.style.marginBottom = 0
                form.style.paddingTop = 0
                form.style.paddingBottom = 0
            })
        })

        let animationEndHandler = function(e) {
            if (e.target.matches(this.formSelector) && e.propertyName == 'height') {
                form.removeEventListener(e.type, animationEndHandler)
                form.style.opacity = null
                form.style.height = null
                form.style.marginTop = null
                form.style.marginBottom = null
                form.style.paddingTop = null
                form.style.paddingBottom = null
                
                if(this.deleteForm(form)) {
                    this.clearAnimations(form)
                }
            }
        }.bind(this)
        
        form.addEventListener('transitionend', animationEndHandler, false)
    }

    moveFormAnimation(form, target, formOldBox, targetOldBox) {
        let formNewBox = form.getBoundingClientRect(),
            targetNewBox = target.getBoundingClientRect(),
            formDelta = formOldBox.top  - formNewBox.top,
            targetDelta = targetOldBox.top  - targetNewBox.top

        requestAnimationFrame(function() {
            form.style.transform  = 'translateY(' + formDelta + 'px)'
            form.style.transition = 'transform 0s'
            target.style.transform  = 'translateY(' + targetDelta + 'px)'
            target.style.transition = 'transform 0s'
        
            requestAnimationFrame(function() {
                form.style.transform  = ''
                form.style.transition = 'transform 300ms'
                target.style.transform  = ''
                target.style.transition = 'transform 300ms'
            })
        })

        let animationEndHandler = function(e) {
            if (e.target.matches(this.formSelector) && e.propertyName == 'transform') {
                e.target.removeEventListener(e.type, animationEndHandler)
                this.clearAnimations(e.target)
            }    
        }.bind(this)

        form.addEventListener('transitionend', animationEndHandler, false)
        target.addEventListener('transitionend', animationEndHandler, false)
    }

    clearAnimations(form) {
        form.style.transform = null
        form.style.transformOrigin = null
        form.style.transition = null
    }

    managementForm(name) {
        let input = this.getFormInput(name, this.managementFormParent)
        if (input) { 
            return input
        }
        throw new Error("Django Formset management form is missing.")
    }

    parseHTML(htmlString) {
        let body = document.implementation.createHTMLDocument('').body
        body.innerHTML = htmlString
        return body.childNodes[0]
    }

    toArray(nodeList) {
        return Array.prototype.slice.call(nodeList)
    }

    get formsetPrefix() {
        return this.data.get('prefix')
    }

    get deletedClass() {
        return this.data.get('deletedClass')
    }

    get isAnimated() {
        return !this.data.get('noAnimations')
    }

    get managementFormParent() {
        return this.hasManagementFormParentValue ? document.querySelector(this.managementFormParentValue) : this.element
    }

    get body() {
        return this.bodyTarget
    }

    get forms() {
        return this.formTargets
    }

    get formCount() {
        return this.body.children.length
    }

    get activeForms() {
        return this.toArray(this.body.querySelectorAll(`${ this.formSelector }:not([data-form-deleted])`))
    }

    get activeFormCount() {
        return this.activeForms.length
    }

    get totalFormCount() {
        return parseInt(this.managementForm('TOTAL_FORMS').getAttribute('value'))
    }

    set totalFormCount(value) {
        this.managementForm('TOTAL_FORMS').setAttribute('value', value)
    }

    get initialFormCount() {
        return parseInt(this.managementForm('INITIAL_FORMS').getAttribute('value'))
    }

    get minForms() {
        return parseInt(this.managementForm('MIN_NUM_FORMS').getAttribute('value'))
    }

    get maxForms() {
        return parseInt(this.managementForm('MAX_NUM_FORMS').getAttribute('value'))
    }

    get canAdd() {
        return this.activeFormCount < this.maxForms
    }

    get canDelete() {
        return this.activeFormCount > this.minForms
    }

    get emptyForm() {
        return this.emptyFormTarget
    }

    get formSelector() {
        return `[data-${ this.identifier }-target="form"]`
    }

    get btnAddSelector() {
        return `[data-action="click->${ this.identifier }#add"]`
    }

    get btnDeleteSelector() {
        return `[data-action="click->${ this.identifier }#delete"]`
    }

    get btnMoveUpSelector() {
        return `[data-action="click->${ this.identifier }#moveUp"]`
    }

    get btnMoveDownSelector() {
        return `[data-action="click->${ this.identifier }#moveDown"]`
    }
}